<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/purchaseContractService'
import qualitySpecificationService from '../api/purchaseContractQualitySpecificationService'
import {
  CargillCrudMetaView,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {},
      createValidationSchema: Function
    }
  },
  methods: {
    async getQualityMeta() {
      const qualityMeta = await qualitySpecificationService.getMeta()
      qualityMeta.name = 'purchaseContractQualitySpecification'
      qualityMeta.masterName = 'purchaseContractId'
      qualityMeta.fields = qualityMeta.fields.filter(
        (field) => field.id != 'purchaseContract'
      )
      qualityMeta.detailsObject = {
        service: qualitySpecificationService
      }
      return qualityMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getQualityMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      this.fieldsById = _.keyBy(meta.fields, (field) => field.id)
      this.fieldsById.material.options = ctx.materialOptions
      this.fieldsById.center.options = ctx.centerOptions
      this.fieldsById.origin.options = ctx.centerOptions
      this.fieldsById.quantityUnity.options = ctx.quantityUnityOptions
      this.fieldsById.contractPricePointsUnity.options =
        ctx.contractPricePointsUnityOptions
      this.fieldsById.incoterm.options = ctx.incotermOptions
      this.fieldsById.counterPartySector.options =
        ctx.contractCounterPartySectorOptions
      this.generateValidationSchema()
      meta.details = metaDetails
      return meta
    },
    generateValidationSchema() {
      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const requiredProjection = [
          'company',
          'quantityUnity',
          'positionMonth'
        ]
        requiredProjection.forEach((field) => {
          const fieldSchema = validationSchema[field]
          validationSchema[field] = fieldSchema.when(['isContractProjection'], {
            is: (isContractProjection) => isContractProjection != null,
            then: fieldSchema.required().label(translate(field)),
            otherwise: fieldSchema
          })
        })
        const requiredNotProjection = [
          'cropYear',
          'contractDate',
          'beginShipPeriod',
          'endShipPeriod'
        ]
        requiredNotProjection.forEach((field) => {
          const fieldSchema = validationSchema[field]
          validationSchema[field] = fieldSchema.when(['isContractProjection'], {
            is: (isContractProjection) => !isContractProjection,
            then: fieldSchema.required().label(translate(field)),
            otherwise: fieldSchema
          })
        })
        return yup.object().shape(validationSchema)
      }
    }
  },
  created() {
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>
